import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import Action from './Action';
import Panel from './Panel';
import { Spacer } from './Spacer';

import {
  CNAME,
  MORE_LABEL,
  RIGHT,
  SHOW_ALL,
  VARIANT_PANEL_LIST,
  VARIANT_LIST,
} from '../CONSTANTS';

const Actions = ({
  action,
  actions,
  children,
  className: propClassName,
  id,
  menuAlignment,
  show,
  iconOnly,
  menuLabel,
  variant,
  hideOnSelect
}) => {
  const className = classnames(CNAME, propClassName, { [`variant-${variant}`]: !!variant });
  const { visible, hasExtra, extra } = useMemo(() => {
    // convert 'show' prop to valid integer
    const visibleCount = typeof show === 'number' ? show : -1;
    // actions provided as prop with child <Action /> props;
    const childArgs = React.Children.toArray(children).map((c) => c.props);
    // merge prop provided actions with child actions
    const compositeArgs = [action, ...actions, ...childArgs];
    // remove empty objects and assign indeces
    const all = compositeArgs.filter(Boolean).map((item, idx) => ({
      ...item,
      actionIndex: idx,
    }));
    // provide the full list, visible list and expanded list.
    return {
      all, // every action
      visible: all.slice(0, visibleCount), // displayed actions
      extra: all.slice(visibleCount), // dropdown actions
      hasExtra: all.length > visibleCount, // helper boolean
    };
  }, [show, children, action, actions]);

   function toAction(act) {
    // render a <Spacer /> if desired
    if (act.spacer) return <Spacer {...act} key={`${id}-spacer-${act.actionIndex}`} />;
    // render an <Action /> if theres a label included
    if (act.label) return <Action {...act} key={`${id}-action-${act.actionIndex}`} />;
    // do nothing otherwise
    return null;
  }

  return (
    <div className={className}>
      {visible.map(toAction)}
      {hasExtra && (
        <Panel align={menuAlignment} hideOnSelect={hideOnSelect} iconOnly={iconOnly} menuLabel={menuLabel}>
          {extra.map(toAction)}
        </Panel>
      )}
    </div>
  );
};

Actions.propTypes = {
  action: PropTypes.shape({
    addOnPrepend: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    spacer: PropTypes.bool,
  }),
  actions: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  className: PropTypes.string,
  hideOnSelect:  PropTypes.bool,
  iconOnly: PropTypes.bool,
  id: PropTypes.string,
  menuAlignment: PropTypes.string,
  menuLabel: PropTypes.string,
  textOnly: PropTypes.bool,
  show: PropTypes.oneOfType([PropTypes.oneOf([SHOW_ALL]), PropTypes.number]),
  variant: PropTypes.oneOf([null, VARIANT_PANEL_LIST, VARIANT_LIST]),
};

Actions.defaultProps = {
  actions: [],
  action: undefined,
  id: uuid(),
  menuAlignment: RIGHT,
  menuLabel: MORE_LABEL,
  show: 2,
  textOnly: false,
  iconOnly: false,
  className: '',
  children: null,
  variant: null,
  hideOnSelect: false
};
Actions.displayName = 'Actions';

export default Actions;
