import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NS } from '../toolkit';
import { mapToCssModules } from '../Utilities/utils';

const propTypes = {
  active: PropTypes.bool,
  'aria-label': PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icononly: PropTypes.bool,
  floatingAction: PropTypes.bool,
  outline: PropTypes.bool,
  aux: PropTypes.bool,
  textonly: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  close: PropTypes.bool,
  actionLink: PropTypes.bool,
};

const defaultProps = {
  tag: 'button',
  floatingAction: false,
  actionLink: false,
};

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {
    if (this.props.disabled) {
      e && e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      e && e.preventDefault();
      this.props.onClick(e);
    }
  }

  render() {
    let {
      active,
      'aria-label': ariaLabel,
      block,
      className,
      close,
      cssModule,
      color,
      disabled,
      icononly,
      floatingAction,
      outline,
      textonly,
      aux,
      actionLink,
      type,
      size,
      tag: Tag,
      innerRef,
      ...attributes
    } = this.props;

    if (close && typeof attributes.children === 'undefined') {
      attributes.children = <span aria-hidden>×</span>;
    }

    // btn types are passed as bool props. Add class when one is present.
    const btnType = outline
      ? '-outline'
      : textonly
      ? '-textonly'
      : floatingAction
      ? '-floating-action'
      : aux
      ? '-auxiliary btn-textonly'
      : actionLink
      ? '-action-link link'
      : null;

    // if I size is passed or btn is icon only, append size to classname.
    const btnSize = size ? `-${size}` : icononly ? '-sm' : null;

    const btnColor = color ? `-${color}` : null;

    const classes = mapToCssModules(
      classNames(
        className,
        { close },
        close || actionLink || 'btn',
        close || (btnType && `btn${btnType}`),
        close || (btnSize && `btn${btnSize}`),
        close || (btnColor && `btn${btnColor}`),
        disabled && 'disabled',
        active && 'active',
        icononly && !floatingAction && 'btn-icon-only',
        block && !floatingAction && 'btn-block'
      ),
      cssModule
    );

    if (attributes.href && Tag === 'button') {
      Tag = 'a';
    }

    if (Tag === 'button' && attributes.onClick && type !== 'submit') {
      type = 'button';
    }

    const defaultAriaLabel = close ? 'Close' : null;

    return (
      <Tag
        type={type}
        {...attributes}
        className={classes}
        disabled={disabled}
        ref={innerRef}
        onClick={this.handleOnClick}
        aria-label={ariaLabel || defaultAriaLabel}
      />
    );
  }
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
