/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useChip } from './useChip';

export function Chip(props) {
  const { content, before, after, tagProps } = useChip(props);
  return (
    <span {...tagProps}>
      {before}
      {content}
      {after}
    </span>
  );
}

export const ChipColors = ['light', 'medium', 'dark'];
export const ChipTypes = ['base', 'action', 'filter', 'select'];
export const IconNames = [
  '360',
  '360-exterior',
  '360-interior-front',
  '360-interior-rear',
  'alarm-add',
  'alarm-clock',
  'alarm-set',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrows-left-right',
  'attach',
  'back-to-start',
  'barcode',
  'bell',
  'bell-o',
  'blocked',
  'bolt',
  'bubble',
  'bubbles',
  'bullet',
  'calendar',
  'camcorder',
  'camera',
  'cancel-circle',
  'cancel-circle-o',
  'car',
  'car-side',
  'checkbox-checked',
  'checkbox-unchecked',
  'checkmark',
  'checkmark-circle',
  'chevron-down',
  'chevron-down-chamfered',
  'chevron-down-light',
  'chevron-left',
  'chevron-left-light',
  'chevron-right',
  'chevron-right-light',
  'chevron-up',
  'chevron-up-light',
  'clipboard',
  'clipboard-checked',
  'clock',
  'cloud-upload',
  'condition-report',
  'credit-card',
  'cross',
  'cross-small',
  'cup',
  'damages',
  'details',
  'direction',
  'disk',
  'dollar-o',
  'edit',
  'enlarge',
  'envelope',
  'envelope2',
  'error-triangle',
  'eye',
  'eye-blocked',
  'file-empty',
  'file-excel',
  'file-pdf',
  'file-spreadsheet',
  'file-text',
  'files-empty',
  'filter',
  'fork-spoon',
  'fuel',
  'gavel',
  'gear',
  'graph-pie',
  'grid',
  'grid-small',
  'heart',
  'heart-o',
  'home',
  'image',
  'images',
  'inbox',
  'info',
  'info-o',
  'left-right',
  'link',
  'list',
  'location',
  'location-area',
  'locked',
  'manheim-medallion',
  'medal',
  'megaphone',
  'menu',
  'minus',
  'minus-circle',
  'minus-circle-o',
  'minus3',
  'mobile',
  'new-window',
  'notification-circle',
  'odometer',
  'paint-can',
  'paint-drop',
  'pause',
  'pin',
  'pin2',
  'play',
  'plus',
  'plus-circle',
  'plus-circle-o',
  'printer',
  'qr-code',
  'refresh',
  'reset-time',
  'rotate-left',
  'rotate-right',
  'search',
  'shield',
  'shield-check',
  'shrink',
  'sort',
  'sound-off',
  'sound-on',
  'spinner',
  'star-empty',
  'star-full',
  'star-half',
  'stopwatch',
  'three-dots-horizontal',
  'three-dots-vertical',
  'thumbs-down',
  'thumbs-up',
  'trash',
  'triangle-down-small',
  'triangle-left-small',
  'triangle-right-small',
  'triangle-up-small',
  'triangle-down',
  'triangle-left',
  'triangle-right',
  'triangle-up',
  'truck',
  'unlink',
  'unlocked',
  'user',
  'user-add',
  'users',
  'wifi',
  'zoom-in',
  'zoom-out',
  'arrows-up-down',
  'profile-ccw-circle-arrow',
  'price-tag',
  'grounding',
];

Chip.propTypes = {
  color: PropTypes.oneOf(ChipColors),
  type: PropTypes.oneOf(ChipTypes),
  label: PropTypes.string,
  transparent: PropTypes.bool,
  actionable: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  removeable: PropTypes.bool,
  iconBefore: PropTypes.oneOf(IconNames),
  iconAfter: PropTypes.oneOf(IconNames),
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Chip.defaultProps = {
  color: 'medium',
  type: 'base',
  label: null,
  selected: false,
  selectable: false,
  actionable: false,
  removeable: false,
  transparent: false,
  iconBefore: undefined,
  iconAfter: undefined,
  bold: false,
  uppercase: false,
  href: null,
  tabIndex: 0,
  children: null,
  onClick: () => {},
};

export default Chip;
