/* eslint-disable react/jsx-filename-extension  */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '../Input/Label';
import Input from '../Input/Input';

const SwitchTypes = ['YesNo', 'YES', 'NO', 'OnOff', 'ON', 'OFF'];
const SwitchSizes = ['sm', 'md', 'lg'];

const Switch = ({
  disabled,
  label,
  labelAlignment,
  onChange = () => {},
  statusText,
  size,
  readonly,
  ...props
}) => {
  const [status, setStatus] = useState(props.status === true);
  const [mouseOver, setMouseOver] = useState(false);
  const statusTextOptions = [];
  statusTextOptions[SwitchTypes[0]] = [SwitchTypes[1], SwitchTypes[2]];
  statusTextOptions[SwitchTypes[3]] = [SwitchTypes[4], SwitchTypes[5]];
  const statusTexts = statusTextOptions[statusText];
  const getStatusText = () => (status ? statusTexts[0] : statusTexts[1]);
  const labelAlign = `align-${labelAlignment}`;
  const statusTextClassNames = classNames(
    disabled && ' disabled ',
    status && 'bold',
    readonly && 'readonly'
  );
  const labelSize = size !== '' && SwitchSizes.includes(size) ? size : 'md'; // handle empty value supplied
  const labelClassNames = classNames(
    'switch-label',
    labelAlign,
    `label-${labelSize}`
  );
  const handleChange = () => {
    if (disabled || readonly) return;
    setStatus(!status);
    setMouseOver(false);
    onChange(status, { mouseOver, statusTexts });
  };

  const handleOnMouseOver = () => {
    setMouseOver(true);
  };

  const handleOnMouseOut = () => {
    setMouseOver(false);
  };

  const getMouseOverClass = () => {
    const classPrefix = 'icon prism-icon-checkmark-circle';
    return mouseOver ? `${classPrefix} is-mouse-over` : classPrefix;
  };

  return (
    <div className={`switch ${labelAlign}`}>
      <div className={labelClassNames}>{label}</div>
      <div
        className="switch-clickable"
        onFocus={handleOnMouseOver}
        onBlur={handleOnMouseOut}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        {!readonly && (
          <Label
            aria-selected={status}
            className={`switch-wrapper ${labelAlign}`}
          >
            <Input
              aria-label="checkbox"
              disabled={disabled}
              type="checkbox"
              onChange={handleChange}
              checked={status}
            />
            <span className="switch-track" />
            <span className={`switch-thumb ${getMouseOverClass()}`} />
          </Label>
        )}
        <Label
          aria-label="status"
          className={`switch-status-text ${statusTextClassNames} ${labelAlign}`}
          onClick={handleChange}
          aria-hidden="true"
        >
          {getStatusText()}
        </Label>
      </div>
    </div>
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelAlignment: PropTypes.oneOf(['top', 'left', 'right']),
  onChange: PropTypes.func,
  status: PropTypes.bool,
  statusText: PropTypes.oneOf(SwitchTypes),
  size: PropTypes.oneOf(SwitchSizes),
  readonly: PropTypes.bool,
};

Switch.defaultProps = {
  labelAlignment: 'left',
  disabled: false,
  label: '',
  status: false,
  statusText: SwitchTypes[3],
  size: 'md',
  readonly: false,
  onChange: () => {},
};

export default Switch;
