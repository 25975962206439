/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useIcon } from './useIcon';

export function IconSvg({ glyph, ...props }) {
  const icon = useIcon(glyph, props.color);
  if (!icon) return null;

  const { title, color, height, ...htmlAttributes } = props;

  const svgProps = React.useMemo(() => {
    const iconWidth = icon.width || 1024;
    const floatWidth = (iconWidth / 1024) * parseFloat(props.height, 10);
    const isHex = color && color.includes('#');
    const style = isHex
      ? { ...htmlAttributes.style, color, fill: color }
      : htmlAttributes.style;
    return {
      ...htmlAttributes,
      width: `${floatWidth}px`,
      viewBox: props.viewBox || `0 0 ${iconWidth} 1024`,
      className: `icon prism-icon-svg prism-icon-${glyph} ${props.className} icon-${color || 'default'}`,
      height,
      style,
    };
  }, [props]);

  return (
    <svg {...svgProps} role="figure" aria-label={title || glyph} title={title || glyph}>
      {icon.pathJsx}
    </svg>
  );
}

IconSvg.propTypes = {
  glyph: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  xmlns: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

IconSvg.defaultProps = {
  height: '16px',
  width: undefined,
  viewBox: null,
  xmlns: 'http://www.w3.org/2000/svg',
  path: null,
  className: '',
  title: '',
  glyph: null,
  color: null,
};

export default IconSvg;
