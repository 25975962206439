/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import IconSelection from '../../styles/fonts/prism-icon-font/selection.json';

export const IconNames = IconSelection.icons.map((i) => i.properties.name);
export function useIcon(glyph, color) {
  const selectedIcon = IconSelection.icons.find((i) => i.properties.name === glyph) || false;

  if (!selectedIcon) return false;
  const isNull = !color;
  const isHex = color && color.includes('#');
  const fill = !isNull && isHex ? color : 'currentColor';

  const pathJsx = React.useMemo(() => {
    const { icon } = selectedIcon;
    const { paths } = icon;
    const hashKey = (k) => k.trim().slice(10, 50);
    return (
      paths.map((d) => (
        <path d={d} key={d.slice(0, 40).trim()} fill={fill} />
      )) || <path fill={fill} />
    );
  });
  return {
    name: selectedIcon.properties.name,
    paths: selectedIcon.icon.paths,
    width: selectedIcon.icon.width,
    pathJsx,
  };
}

export default useIcon;
