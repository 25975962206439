import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { columnPropType, useCol } from '@prism/col';

const Label = ({
  className, hidden, tag: Tag, check, fontSize, for: htmlFor, ...props
}) => {
  const { className: colClasses, ...attributes } = useCol(props);

  const computedClassName = classNames({
    [className]: className,
    'col-form-label': true,
    'sr-only': hidden,
    'form-check-label': check,
    [colClasses]: colClasses,
    [`font-size-${fontSize}`]: fontSize,
  });

  return <Tag htmlFor={htmlFor} {...attributes} className={computedClassName} />;
};

Label.propTypes = {
  check: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  for: PropTypes.string,
  hidden: PropTypes.bool,
  size: PropTypes.string,
  tag: PropTypes.string,
  widths: PropTypes.arrayOf(columnPropType),
  xs: columnPropType,
  sm: columnPropType,
  md: columnPropType,
  lg: columnPropType,
  xl: columnPropType,
};

Label.defaultProps = {
  check: false,
  children: null,
  className: '',
  fontSize: '',
  for: undefined,
  hidden: false,
  size: '',
  tag: 'label',
  widths: ['xs', 'sm', 'md', 'lg', 'xl'],
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
};

export default Label;
