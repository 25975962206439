/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { useIcon } from './useIcon';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  glyph: PropTypes.string,
  ariaTitle: PropTypes.string,
  fill: PropTypes.string,
  Tag: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  xmlns: PropTypes.string,
  path: PropTypes.string,
};

const defaultProps = {
  Tag: 'i',
  height: '16px',
  width: undefined,
  viewBox: null,
  xmlns: 'http://www.w3.org/2000/svg',
  path: undefined,
  fill: '#000',
  className: '',
  glyph: null,
  color: null,
  ariaTitle: undefined,
};

export const Icon = (props) => {
  const {
    glyph, color, className, Tag, ariaTitle, ...extra
  } = props;
  const icon = useIcon(glyph);
  if (!icon) return null;

  const attrs = {
    ...extra,
    className: clsx('icon', [
      className,
      `prism-icon-${glyph}`,
      `icon-${color || 'default'}`,
    ]),
    'aria-hidden': true,
    'aria-label': ariaTitle || glyph,
  };

  return <Tag {...attrs} />;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
