import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Label from './Label';
import Input from './Input';

const propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  rows: PropTypes.string,
};

const defaultProps = {
  id: uuid(),
  onChange: null,
};

class TextCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remaining: 120,
      max: 120,
      reachedMax: false,
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleCount = this.handleCount.bind(this);
  }

  componentDidMount() {
    this.setState({ max: this.props.maxLength });
    this.setState({ remaining: this.props.maxLength });
  }

  updateValue = () => {};

  handleChange(event) {
    this.setState({
      remaining: this.props.maxLength - event.target.value.length,
    });
    this.handleCount(event);
  }
  handlePaste(e) {
    const targetData = e.clipboardData.getData('text').split('');
    const dataLength = targetData.length;
    let limitData = [];
    let index;
    for (index = 0; index < this.state.max && index < dataLength; index++) {
      limitData.push(targetData[index]);
    }
    let dataJoin = limitData.join('');
    this.setState({ value: dataJoin });
  }

  handleCount(e) {
    const { value } = e.target;
    const { reachedMax, max, remaining } = this.state;

    const setReachedMax = (isMax) => {
      this.setState({ reachedMax: isMax });
    };

    const setRemaining = (rem) => {
      this.setState({ remaining: rem });
    };
    const setValue = (value) => {
      const { onChange } = this.props;
      this.setState({ value });
      onChange && onChange({ value });
    };

    if (reachedMax && value.length < max) {
      let newLength = max - value.length;
      setRemaining(newLength);
      setReachedMax(false);
      setValue(value);
    } else if (this.state.remaining === 0) {
      let newVal = value.slice(0, -1);
      setReachedMax(true);
      setRemaining(0);
      setValue(newVal);
    } else if (remaining < 1 && value.length > max) {
      let newVal = value.slice(0, remaining);
      setReachedMax(true);
      setValue(newVal);
      setRemaining(0);
    } else {
      let newLength = max - value.length;
      setRemaining(newLength);
      setValue(value);
    }
  }

  render() {
    const { remaining, reachedMax, value } = this.state;
    const { id, label, placeholder, rows } = this.props;
    const counterClass = classNames('form-text mt-8 textarea-counter__count', {
      'text-danger': remaining === 0,
    });
    return (
      <div className="textarea-counter">
        <Label htmlFor="exampleFormControlTextarea1">{label}</Label>
        <Input
          type="textarea"
          className="form-control text-area__textarea "
          id={id}
          placeholder={placeholder || 'Enter some text'}
          rows={rows}
          value={value}
          onChange={this.handleChange}
          onPaste={(e) => this.handlePaste(e)}
        />
        <small id="textCounter" className={counterClass}>
          {remaining} characters remaining
        </small>
      </div>
    );
  }
}

TextCounter.propTypes = propTypes;

TextCounter.defaultProps = defaultProps;

export default TextCounter;
