import React from 'react';
import PropTypes from 'prop-types';

const COLOR_LIGHT = 'light';
const COLOR_MEDIUM = 'medium';
const COLOR_DARK = 'dark';

const TYPE_BASE = 'base';
const TYPE_ACTION = 'action';
const TYPE_FILTER = 'filter';
const TYPE_SELECT = 'select';

const propTypes = {
  color: PropTypes.oneOf([COLOR_LIGHT, COLOR_MEDIUM, COLOR_DARK]),
  type: PropTypes.oneOf([TYPE_BASE, TYPE_ACTION, TYPE_FILTER, TYPE_SELECT]),
  label: PropTypes.string,
  actionable: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  removeable: PropTypes.bool,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  href: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultTypes = {
  color: COLOR_MEDIUM,
  type: TYPE_BASE,
  label: null,
  selected: false,
  iconBefore: null,
  iconAfter: null,
  bold: false,
  uppercase: false,
  href: null,
  tabIndex: '0',
};

const Chip = ({
  color,
  type,
  id,
  href,
  tabIndex,
  label,
  bold,
  iconBefore,
  iconAfter,
  uppercase,
  selected,
  children,
  onClick,
}) => {
  let classList = 'chip';
  let Tag = 'span';

  switch (type) {
    case TYPE_ACTION:
      color = COLOR_LIGHT;
      classList += ' chip-actionable';
      Tag = 'a';
      break;
    case TYPE_FILTER:
      color = COLOR_LIGHT;
      classList += ' chip-filter';

      if (!iconAfter) {
        iconAfter = 'cross';
      }

      break;
    case TYPE_SELECT:
      // color = COLOR_LIGHT;
      classList += ' chip-selectable';

      if (selected) {
        classList += ' chip-selected';
      }
      break;
    default:
      break;
  }

  let iconBeforeClassList = 'icon prism-icon-' + iconBefore;
  let iconAfterClassList = 'icon prism-icon-' + iconAfter;

  if (color) {
    classList += ' chip-' + color;
  }

  if (bold) {
    classList += ' font-weight-bold';
  }

  if (uppercase) {
    classList += ' text-uppercase';
  }

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      e.preventDefault();
    }
  };

  const tagProps = {
    id,
    href,
    className: classList,
    tabIndex: +(tabIndex | 0),
  };

  if (onClick) {
    tagProps.onClick = handleClick;
  }

  let content = label ? label : children;

  return (
    <Tag {...tagProps}>
      {iconBefore && <i className={`${iconBeforeClassList} chip-icon-left`} />}
      {content}
      {iconAfter && <i className={`${iconAfterClassList} chip-icon-right`} />}
    </Tag>
  );
};

Chip.propTypes = propTypes;
Chip.defaultTypes = defaultTypes;

Chip.COLOR_LIGHT = COLOR_LIGHT;
Chip.COLOR_MEDIUM = COLOR_MEDIUM;
Chip.COLOR_DARK = COLOR_DARK;

Chip.TYPE_BASE = TYPE_BASE;
Chip.TYPE_ACTION = TYPE_ACTION;
Chip.TYPE_FILTER = TYPE_FILTER;
Chip.TYPE_SELECT = TYPE_SELECT;

export default Chip;
