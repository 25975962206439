import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../Utilities/utils';

const COMPONENT_CNAME = 'modal-footer';

const propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  cssModule: PropTypes.oneOfType([PropTypes.object]),
};

const defaultProps = {
  tag: 'div',
  className: '',
  cssModule: null,
};

const ModalFooter = (props) => {
  const {
    className,
    cssModule,
    tag: Tag,
    children,
    size,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(COMPONENT_CNAME, className),
    cssModule
  );

  return (
    <Tag {...attributes} className={classes}>
      {children}
    </Tag>
  );
};

ModalFooter.propTypes = propTypes;
ModalFooter.defaultProps = defaultProps;
ModalFooter.COMPONENT_CNAME = COMPONENT_CNAME;

export default ModalFooter;
