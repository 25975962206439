import React from 'react';
import PropTypes from 'prop-types';
import { deprecated } from '../Utilities/utils';
import useCol from './useCol';

const Col = (props) => {
  const { tag: Tag, ...attributes } = useCol(props);
  return <Tag {...attributes} />;
};

// PropTypes helper
export const columnPropType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    push: deprecated(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]), 'Please use the prop "order"'),
    pull: deprecated(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]), 'Please use the prop "order"'),
    size: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    order: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
]);

Col.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  widths: PropTypes.arrayOf(PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])),
  xs: columnPropType,
  sm: columnPropType,
  md: columnPropType,
  lg: columnPropType,
  xl: columnPropType,
};

Col.defaultProps = {
  className: '',
  tag: 'div',
  widths: ['xs', 'sm', 'md', 'lg', 'xl'],
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
};

export default Col;
