import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Button from '@prism/button';

const propTypes = {
  // label: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  id: PropTypes.string,
};

const defaultProps = {
  value: 0,
  max: 5,
  min: 0,
  id: uuid(),
};

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      prevValue: props.value,
      max: props.max,
      min: props.min,
      minHit: true,
      maxHit: false,
    };
    this.handleIncrementClick = this.handleIncrementClick.bind(this);
    this.handleDecrementClick = this.handleDecrementClick.bind(this);
    this.sanitizeValue = this.sanitizeValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleDecrementClick(e) {
    e.preventDefault();

    const { value } = this.state;
    const newValue = value - 1;
    this.sanitizeValue(newValue);
  }

  handleIncrementClick(e) {
    e.preventDefault();

    const { value } = this.state;
    const newValue = value + 1;

    this.sanitizeValue(newValue);
  }

  sanitizeValue(newValue) {
    const { value, min, max } = this.state;

    let valueState = {
      value: newValue < min || newValue > max ? value : newValue,
    };

    let hitState = {
      minHit: valueState.value <= min,
      maxHit: valueState.value >= max,
    };

    this.setState(Object.assign(valueState, hitState));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value } = this.state;
    if (value !== prevState.value) {
      let { onChange } = this.props;
      onChange && onChange({ prevValue: prevState.value, value });
      this.setState(this.getMinMaxHits(value));
    }
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({
      value,
      ...this.getMinMaxHits(value),
    });
  }

  getMinMaxHits(value) {
    const { min, max } = this.state;

    return {
      minHit: value <= min,
      maxHit: value >= max,
    };
  }

  render() {
    const { value, prevValue } = this.state;
    const { className, id, value: propsValue, onChange } = this.props;

    if (prevValue !== propsValue) {
      this.setState({
        value: propsValue,
        prevValue: propsValue,
      });
    }

    const inputClasses = classNames(
      className && className,
      'input-group number-input__group'
    );

    return (
      <div className={inputClasses}>
        <div className="input-group-prepend">
          <Button
            data-test="decrement-button"
            disabled={this.state.minHit}
            color="secondary"
            onClick={this.handleDecrementClick}
            className="number-input__decrement"
            id={`${id}-decrement`}
          >
            <i className="icon prism-icon-minus3" />
          </Button>
        </div>
        <input
          onChange={this.handleChange}
          type="text"
          className="form-control"
          id={id}
          value={value}
          placeholder="0"
          aria-label="number input"
          aria-describedby="basic-addon1"
        />
        <div className="input-group-append">
          <Button
            data-test="increment-button"
            disabled={this.state.maxHit}
            color="secondary"
            onClick={this.handleIncrementClick}
            className="number-input__increment"
            id={`${id}-increment`}
          >
            <i className="icon prism-icon-plus" />
          </Button>
        </div>
      </div>
    );
  }
}

NumberInput.propTypes = propTypes;

NumberInput.defaultProps = defaultProps;

export default NumberInput;
