import React, { Component } from 'react';
import classNames from 'classnames';
import Input from './Input';
class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPass: false,
    };

    this.togglePasswordMask = this.togglePasswordMask.bind(this);
  }
  togglePasswordMask() {
    console.log('clicked');
    this.setState((prevState) => ({
      showPass: !prevState.showPass,
    }));
  }
  render() {
    const icnClass = classNames(
      'text-input__icon-right',
      'prism-icon',
      'icon',
      'prism-icon-md',
      'pr-3',
      'password-toggle__icon',
      {
        ' prism-icon-eye icon-secondary': !this.state.showPass,
        'prism-icon-eye-blocked text-charcoal': this.state.showPass,
      }
    );
    const props = this.props;
    delete props.rules;
    const { showPass } = this.state;
    return (
      <div className="d-flex align-items-center inline">
        <Input
          {...props}
          type={showPass ? 'text' : 'password'}
          className="font-size-md form-control text-input__has-icon-right"
          placeholder="Password"
        />

        <span
          onClick={this.togglePasswordMask}
          className={icnClass}
          name={showPass ? 'eye' : 'eye-blocked'}
        />
      </div>
    );
  }
}
export default PasswordInput;
