/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = (props) => {
  const { className, fluid, tag: Tag, ...attributes } = props;

  const classes = classNames(className, {
    container: !fluid,
    'container-fluid': fluid,
  });

  return <Tag {...attributes} className={classes} />;
};

Container.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  fluid: PropTypes.bool,
  className: PropTypes.string,
};

Container.defaultProps = {
  tag: 'div',
  fluid: false,
  className: '',
};

export default Container;
