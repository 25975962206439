import InputGroup from './InputGroup';
import InputGroupAddon from './InputGroupAddon';
import InputGroupButton from './InputGroupButton';
import InputGroupButtonDropdown from './InputGroupButtonDropdown';
import InputGroupText from './InputGroupText';

InputGroup.Addon = InputGroupAddon;
InputGroup.Button = InputGroupButton;
InputGroup.ButtonDropdown = InputGroupButtonDropdown;
InputGroup.Text = InputGroupText;

export {
  InputGroupAddon,
  InputGroupButton,
  InputGroupButtonDropdown,
  InputGroupText,
};

export default InputGroup;
