/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import classnames from 'classnames';
import { IconSvg } from '@prism/icon';

const makeIcon = ({ glyph, color, align = 'left' }) => {
  if (!glyph) return null;
  const args = {
    glyph,
    color: color === 'dark' ? '#fff' : '#4a4a4a',
    height: '10px',
    className: `icon prism-icon-${glyph} chip-icon-${align}`,
  };
  return <IconSvg {...args} />;
};

export function useChip(props) {
  const {
    color,
    type,
    label,
    selected,
    selectable,
    actionable,
    removeable,
    transparent,
    iconBefore,
    iconAfter,
    bold,
    uppercase,
    href,
    ...extraProps
  } = props;
  const flags = {
    isAction: type === 'action',
    isFilter: type === 'filter',
    isSelectable: type === 'select',
    isTransparent: props.transparent,
    isBold: props.bold,
    isSelected: props.selected,
    isUppercase: props.uppercase,
    isCross: !iconAfter && type === 'filter',
    hasIcon: iconBefore || iconAfter,
  };

  const className = classnames(
    'chip',
    `chip-${color}`,
    { 'chip-transparent': props.transparent },
    { 'chip-actionable': type === 'action' },
    { 'chip-filter': type === 'filter' },
    { cross: !iconAfter && type === 'filter' },
    { 'chip-selectable': type === 'select' },
    { 'chip-selected': props.selected },
    { 'font-weight-bold': props.bold },
    { 'text-uppercase': props.uppercase }
  );

  const tagProps = {
    ...extraProps,
    className,
  };

  const appendIcon = flags.isFilter ? iconAfter || 'cross' : iconAfter;
  return {
    content: props.label || props.children,
    before: makeIcon({ glyph: iconBefore, color }),
    after: makeIcon({ glyph: appendIcon, color, align: 'right' }),
    flags,
    tagProps,
    extraProps,
  };
}

export default useChip;
