/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { deprecated } from '../Utilities/utils';
import useButton from './useButton';

const Button = (props) => {
  const { Tag, tagProps, isClose, children } = useButton(props);
  return (
    <Tag {...tagProps}>
      {isClose ? <span aria-hidden>×</span> : children}
    </Tag>
  );
};

export const ButtonVariants = [
  'actionLink',
  'aux',
  'floatingAction',
  'outline',
  'textonly',
];
Button.propTypes = {
  actionLink: deprecated(
    PropTypes.bool,
    'Please use prop `variant` with value `actionLink`'
  ),
  aux: deprecated(PropTypes.bool, 'Please use prop `variant` with value `aux`'),
  floatingAction: deprecated(
    PropTypes.bool,
    'Please use prop `variant` with value `floatingAction`'
  ),
  outline: deprecated(
    PropTypes.bool,
    'Please use prop `variant` with value `outline`'
  ),
  textonly: deprecated(
    PropTypes.bool,
    'Please use prop `variant` with value `textonly`'
  ),
  active: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  close: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icononly: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  size: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  type: PropTypes.string,
  variant: PropTypes.oneOf([undefined, ...ButtonVariants]),
};

Button.defaultProps = {
  actionLink: undefined, // deprecated
  aux: undefined, // deprecated
  floatingAction: undefined, // deprecated
  outline: undefined, // deprecated
  textonly: undefined, // deprecated
  active: false,
  block: false,
  children: null,
  className: undefined,
  close: false,
  color: undefined,
  disabled: false,
  icononly: false,
  innerRef: null,
  onClick: undefined,
  size: undefined,
  tag: undefined,
  type: undefined,
  variant: undefined,
};

export default Button;
