import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import { useClickAway } from 'react-use';
import {
  CENTER, LEFT, RIGHT, CNAME_PANEL, CNAME_MASK, MORE_LABEL
} from '../CONSTANTS';
import PanelTrigger from './PanelTrigger';

const propTypes = {
  align: PropTypes.oneOf([CENTER, LEFT, RIGHT]),
  className: PropTypes.string,
  children: PropTypes.node,
  showPanel: PropTypes.bool,
  hideOnSelect: PropTypes.bool,
  panelRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.ref }),
  ]),
  iconOnly: PropTypes.bool,
  menuLabel: PropTypes.string,
};

const defaultProps = {
  align: LEFT,
  iconOnly: false,
  className: '',
  children: null,
  showPanel: false,
  panelRef: null,
  menuLabel:  MORE_LABEL,
};

const Panel = ({
  align,
  iconOnly,
  className: propClassName,
  children,
  menuLabel,
  panelRef,
  hideOnSelect,
  showPanel: propShowPanel,
}) => {
  const ref = React.useRef(panelRef);
  const [visible, toggle] = React.useState(propShowPanel);
  useClickAway(ref, () => {
    if (visible) {
      toggle(!visible);
    }
  });

  const className = classnames(
    CNAME_PANEL,
    {
      'dropdown-menu': true,
      show: visible,
      'align-center': align === CENTER,
      'align-left': align === LEFT,
      'align-right': align === RIGHT,
    },
    propClassName
  );

  const getPlacement = () => {
    switch (align) {
      case CENTER:
        return 'bottom';
      case LEFT:
        return 'bottom-start';
      case RIGHT:
        return 'bottom-end';
      default:
        return 'auto';
    }
  };

  const popperOptions = {
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: [CENTER, LEFT, RIGHT].includes(align)
            ? ['bottom-start', 'bottom-end', 'top-start', 'top-end']
            : undefined,
        },
      },
    ],
  };

  const appendTarget = typeof document !== 'undefined' ? document.body : 'parent';

  const hidePanel = () => hideOnSelect && toggle(!visible);
  

  return (
    <>
      <Tippy
        interactive
        offset={0}
        popperOptions={popperOptions}
        placement={getPlacement()}
        appendTo={appendTarget}
        render={(attrs) => (visible ? (
          <div className="prism-sandbox prism-sandbox">
            <div
              className={className}
              ref={ref}
              role="group"
              {...attrs}
            >
              {React.Children.toArray(children).map((child) => (
                <li onClick={hidePanel} key={`actions-panel-${child.key}`}>{child}</li>
              ))}
            </div>
          </div>
        ) : null)}
        visible={!!visible}
      >
        <PanelTrigger
          onClick={() => toggle(!visible)}
          menuLabel={menuLabel}
          iconOnly={iconOnly}
          showPanel={visible}
        />
      </Tippy>
      {visible && <span className={CNAME_MASK} />}
    </>
  );
};

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;
Panel.displayName = 'Panel';

export default Panel;
