import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../Utilities/utils';
import FormFeedback from './FormFeedback';
import FormGroup from './FormGroup';

const propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  tag: 'form',
};
class Form extends Component {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.submit = this.submit.bind(this);
  }

  getRef(ref) {
    if (this.props.innerRef) {
      this.props.innerRef(ref);
    }
    this.ref = ref;
  }

  submit() {
    if (this.ref) {
      this.ref.submit();
    }
  }

  render() {
    const {
      className,
      cssModule,
      inline,
      tag: Tag,
      innerRef,
      ...attributes
    } = this.props;

    const classes = mapToCssModules(
      classNames(className, inline ? 'form-inline' : false),
      cssModule
    );

    return <Tag {...attributes} ref={innerRef} className={classes} />;
  }
}

Form.Group = FormGroup;
Form.Text = Form.Text;
Form.Feedback = FormFeedback;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
