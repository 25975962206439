import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropToggle from './DropToggle';
import DropdownMenu from './DropdownMenu';

const propTypes = {
  id: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  children: PropTypes.node,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  focus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  containerClasses: PropTypes.string,
  onChange: PropTypes.func,
  defaultSelection: PropTypes.array,
};
const defaultProps = {
  multiple: false,
  isOpen: false,
  defaultSelection: [],
  containerClasses: '',
  onChange: () => true,
  placeholder: 'Select One...',
};

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      optionName: '',
      isOpen: props.isOpen,
      prevIsOpen: props.isOpen,
      selected: props.defaultSelection,
      isFocused: false,
      heading:
        props.defaultSelection && props.defaultSelection.length
          ? props.defaultSelection
          : props.placeholder,
    };
  }

  toggleMenu = (e) => {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  selectOption = (e) => {
    e.preventDefault();

    const { id, value, name } = e.target;
    const { selected } = this.state;
    const { multiple, onChange } = this.props;

    //if option is in array, return true
    const checkForItem = selected.find((value) => value === id);
    //return all other options in array
    const removeMulti = selected.filter((value) => value !== id);
    //if item exists, remove it, else select it.
    const toggleOne = checkForItem ? [] : [id];
    //check multiple options, remove it if it already exists, add it if it doesnt.
    const toggleMulti = checkForItem
      ? removeMulti
      : this.state.selected.concat(id);

    //If multiple prop is passed to dropdown, use toggleMulti
    this.setState((state) => {
      const selected = multiple
        ? (state.selected = toggleMulti)
        : (state.selected = toggleOne);
      selected.value = value;
      selected.id = id;
      selected.name = name;

      onChange({ value, name, selected, multiple });

      return {
        value,
        optionName: name,
        selected,
        heading: name,
        isOpen: false,
        isFocused: true,
      };
    });
  };

  //If clicked node does not contain the ref, close the menu.
  handleClickOutside = (e) => {
    return (
      (typeof this.node !== 'undefined' && this.node.contains(e.target)) ||
      this.setState({ isOpen: false, isFocused: false })
    );
  };

  handleChange = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  render() {
    const { isOpen, prevIsOpen, selected, heading, isFocused } = this.state;
    const {
      children,
      options,
      placeholder,
      disabled,
      size,
      invalid,
      valid,
      focus,
      containerClasses,
      isOpen: propsIsOpen,
    } = this.props;

    if (propsIsOpen !== prevIsOpen) {
      this.setState({
        isOpen: propsIsOpen,
        prevIsOpen: propsIsOpen,
      });
    }

    const dropdownClass = classNames(
      'prism-select',
      size && `prism-select-${size}`
    );

    return (
      <div
        ref={(node) => (this.node = node)}
        className={`${dropdownClass} ${containerClasses}`}
      >
        <DropToggle
          isFocused={isFocused || focus}
          heading={heading}
          disabled={disabled}
          invalid={invalid}
          valid={valid}
          toggleMenu={this.toggleMenu}
        />
        <DropdownMenu
          open={isOpen}
          heading={placeholder}
          selected={selected}
          selectOption={this.selectOption}
          placeholder={placeholder || 'Select One...'}
          options={options}
        >
          {children}
        </DropdownMenu>
        <input type="hidden" value={selected} />
      </div>
    );
  }
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;
export default Dropdown;
