import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  selectOption: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  group: PropTypes.string,
};

const defaultProps = {
  selected: [],
  selectOption: () => true,
  disabled: false,
};
class DrOption extends Component {
  render() {
    //gets a name prop.string, value prop.string, selectOption prop.func, selected prop.sting || prop.number
    const {
      name,
      children,
      value,
      selectOption,
      selected,
      disabled,
      group,
      id,
    } = this.props;

    const getName = name || children;
    const checkId = id || getName;
    const getValue = value || getName;
    const getId = id || getName;
    const isSelected = selected.find((option) => option === checkId);

    //when prop.selected === prop.value, add active class modifier.

    const optionClass = classNames(
      `prism-select__option`,
      isSelected && 'prism-select__option-selected',
      disabled && 'prism-select__option-disabled'
    );

    return (
      <button
        className={optionClass}
        onClick={selectOption.bind(this)}
        selected={selected}
        value={getValue}
        disabled={disabled}
        name={getName}
        className={optionClass}
        id={getId}
        group={group}
      >
        {getName}
      </button>
    );
  }
}
DrOption.propTypes = propTypes;
DrOption.defaultProps = defaultProps;
export default DrOption;
