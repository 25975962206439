import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { AsYouType, parsePhoneNumber, ParseError } from 'libphonenumber-js';
import { FormFeedback } from '@prism/form';
import Input from './Input';
import Label from './Label';

const propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.bool,
  id: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
};

const defaultProps = {
  ariaLabel: 'phoneNumber',
  icon: true,
  id: uuid(),
  invalid: false,
};

const PhoneInput = ({
  ariaLabel,
  className,
  icon,
  id,
  invalid,
  label,
  ...props
}) => {
  const [isValid, setIsValid] = useState(!invalid);
  const [message, setMessage] = useState(null);
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const asYouType = new AsYouType('US');
    const inputValue = asYouType.input(`${event.target.value}`);

    if (asYouType.getChars().length > 10) {
      setIsValid(false);
      return null;
    } else {
      setValue(inputValue);
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleBlur = (event) => {
    let phoneNumber;

    try {
      phoneNumber = parsePhoneNumber(value, 'US');
      setIsValid(true);
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        setIsValid(false);
        setMessage(error.message);
      } else {
        throw error;
      }
    }

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const classes = classNames('input-phone', 'form-control', {
    [className]: !!className,
    'text-input__has-icon-left': !!icon,
  });

  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <div className="input-group align-items-center mb-3">
        {icon ? (
          <i className="icon text-input__icon-left prism-icon-mobile " />
        ) : null}
        <Input
          {...props}
          aria-label={ariaLabel}
          className={classes}
          onBlur={handleBlur}
          onChange={handleChange}
          id={id}
          invalid={!isValid}
          type="phone"
          valid={false}
          value={value}
        />
        <FormFeedback valid={isValid}>
          This is not a valid 10-digit phone number.
        </FormFeedback>
      </div>
    </>
  );
};

PhoneInput.propTypes = propTypes;
PhoneInput.defaultProps = defaultProps;
PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
