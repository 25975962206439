import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../Utilities/utils';

const COMPONENT_CNAME = 'modal-header';
const TITLE_CNAME = `${COMPONENT_CNAME}-title`;

const propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  wrapTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  toggle: PropTypes.func,
  className: PropTypes.string,
  cssModule: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node,
  closeAriaLabel: PropTypes.string,
  charCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  close: PropTypes.oneOfType([PropTypes.object]),
};

const defaultProps = {
  tag: 'h3',
  wrapTag: 'div',
  toggle: null,
  className: '',
  cssModule: null,
  children: null,
  closeAriaLabel: 'Close',
  charCode: 215,
  close: null,
};

const ModalHeader = (props) => {
  const {
    className,
    cssModule,
    children,
    toggle,
    tag: Tag,
    wrapTag: WrapTag,
    closeAriaLabel,
    charCode,
    close,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(COMPONENT_CNAME, className),
    cssModule
  );

  let closeButton;

  if (!close && toggle) {
    // const closeIcon = typeof charCode === 'number'
    //   ? String.fromCharCode(charCode)
    //   : charCode;
    closeButton = (
      <button
        type="button"
        onClick={toggle}
        className={mapToCssModules(
          `${COMPONENT_CNAME}--close close`,
          cssModule
        )}
        aria-label={closeAriaLabel}
      >
        <span className="icon prism-icon-cross" />
      </button>
    );
  }

  return (
    <WrapTag {...attributes} className={classes}>
      <Tag className={mapToCssModules(TITLE_CNAME, cssModule)}>{children}</Tag>
      {close || closeButton}
    </WrapTag>
  );
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;
ModalHeader.COMPONENT_CNAME = COMPONENT_CNAME;
ModalHeader.TITLE_CNAME = TITLE_CNAME;

export default ModalHeader;
