/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const FloatingButton = (props) => {
  const { color, outline, ...attributes } = props;

  let className = '';
  if (outline || color) {
    className += 'btn';
    if (outline) {
      className += '-outline';
    }
    if (color) {
      className += `-${color}`;
    }
  }

  return <Button className={className} {...attributes} />;
};

FloatingButton.propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
};

FloatingButton.defaultProps = {
  color: undefined,
  outline: false,
};

export default FloatingButton;
