import Modal from './Modal';
import ModalBody from './ModalBody';
import ModalCancel from './ModalCancel';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalOkay from './ModalOkay';

Modal.Body = ModalBody;
Modal.Cancel = ModalCancel;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;
Modal.Okay = ModalOkay;

export { ModalBody, ModalCancel, ModalFooter, ModalHeader, ModalOkay };

export default Modal;
