/* eslint react/prefer-stateless-function: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { InputGroupAddon } from '@prism/inputgroup';
import { deprecated, warnOnce } from '../Utilities/utils';
import Label from './Label';
import Input from './Input';
import PhoneInput from './PhoneInput';
import FormGroup from '../Form/FormGroup';

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  bsSize: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  plaintext: PropTypes.bool,
  addon: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  label: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  onClickBefore: PropTypes.func,
  onClickAfter: PropTypes.func,
};

const defaultProps = {
  type: 'text',
  id: `input-${uuid()}`,
  name: null,
};

class InteractiveInput extends React.Component {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.focus = this.focus.bind(this);
    this.state = {
      isPassword: false,
      showPass: false,
    };
    this.togglePasswordMask = this.togglePasswordMask.bind(this);
  }

  togglePasswordMask() {
    this.setState((prevState) => ({
      showPass: !prevState.showPass,
    }));
  }

  componentDidMount() {
    if (this.props.type === 'password') {
      this.setState({
        isPassword: true,
      });
    }
  }
  togglePasswordMask() {
    this.setState((prevState) => ({
      showPass: !prevState.showPass,
    }));
  }

  getRef(ref) {
    if (this.props.innerRef) {
      this.props.innerRef(ref);
    }
    this.ref = ref;
  }

  focus() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  render() {
    let {
      className,
      cssModule,
      type,
      size,
      bsSize,
      state,
      valid,
      invalid,
      tag,
      addon,
      static: staticInput,
      plaintext,
      innerRef,
      name,
      label,
      iconLeft,
      iconRight,
      iconBefore,
      iconAfter,
      onClickLeft,
      onClickRight,
      onClickBefore,
      onClickAfter,
      inline,
      id,
      ...attributes
    } = this.props;

    const { showPass, isPassword } = this.state;
    const checkInput = ['radio', 'checkbox'].indexOf(type) > -1;
    const isPasswordInput = ['password'].indexOf(type) > -1;
    const isNotaNumber = new RegExp('\\D', 'g');
    const isPhone = type === 'phone';
    const fileInput = type === 'file';
    const textareaInput = type === 'textarea';
    const selectInput = type === 'select';
    let Tag = tag || (selectInput || textareaInput ? type : 'input');
    let formControlClass = size ? `form-control-${size}` : 'form-control';

    if (plaintext || staticInput) {
      formControlClass = `${formControlClass}-plaintext`;
      Tag = tag || 'p';
    } else if (fileInput) {
      formControlClass = `${formControlClass}-file`;
    } else if (checkInput) {
      if (addon) {
        formControlClass = null;
      } else {
        formControlClass = 'form-check-input';
      }
    }

    if (
      state &&
      typeof valid === 'undefined' &&
      typeof invalid === 'undefined'
    ) {
      if (state === 'danger') {
        invalid = true;
      } else if (state === 'success') {
        valid = true;
      }
    }

    const spanClasses = classNames(
      'd-flex ',
      'align-items-center',
      inline && 'inline'
    );
    const inputClasses = classNames(
      className,
      'form-control',
      invalid && 'is-invalid',
      valid && 'is-valid',
      size ? `form-control-${size}` : false,
      bsSize ? `form-control-${bsSize}` : false,
      iconLeft && 'text-input__has-icon-left',
      iconRight && 'text-input__has-icon-right',
      isPassword && 'text-input__has-icon-right ',
      formControlClass
    );

    const passwordIconClasses = classNames(
      'text-input__icon-right',
      'icon',
      'prism-icon-md',
      'password-toggle__icon',
      {
        ' prism-icon-eye icon-secondary': !this.state.showPass,
        'prism-icon-eye-blocked text-charcoal': this.state.showPass,
      }
    );

    const iconLeftClasses = classNames(
      'text-input__icon-left icon',
      isPhone ? 'icon prism-icon-mobile' : `${iconLeft}`
    );
    const iconRightClasses = classNames(
      'text-input__icon-right',
      iconRight && `${iconRight}`
    );
    const iconBeforeClasses = classNames(iconBefore && `${iconBefore}`);
    const iconAfterClasses = classNames(iconAfter && `${iconAfter}`);

    const labelClasses = classNames(
      'interactive-input__label',
      inline || (iconBefore && 'icon-before')
    );

    if (isPasswordInput) {
      attributes.type = this.state.showPass ? 'text' : 'password';
    }

    // if (attributes.type !== 'password' || Tag === 'input' || (tag && typeof tag === 'function')) {
    //   attributes.type = type;
    // }
    if (
      attributes.children &&
      !(
        plaintext ||
        staticInput ||
        type === 'select' ||
        typeof Tag !== 'string' ||
        Tag === 'select'
      )
    ) {
      warnOnce(
        `Input with a type of "${type}" cannot have children. Please use "value"/"defaultValue" instead.`
      );
      delete attributes.children;
    }

    return (
      <FormGroup inline={inline}>
        {label && (
          <Label className={labelClasses} for={id}>
            {label}
          </Label>
        )}
        <span className="d-flex align-items-center inline">
          {iconBefore && (
            <InputGroupAddon onClick={onClickBefore} addonType="prepend">
              <span className={iconBeforeClasses} />
            </InputGroupAddon>
          )}
          {iconLeft && (
            <span onClick={onClickLeft} className={iconLeftClasses} />
          )}
          {isPhone ? (
            <PhoneInput
              {...attributes}
              id={id}
              name={name && name}
              className={inputClasses}
            />
          ) : (
            <Input
              {...attributes}
              id={id}
              name={name && name}
              ref={innerRef}
              className={inputClasses}
            />
          )}
          {iconRight && (
            <span onClick={onClickRight} className={iconRightClasses} />
          )}

          {isPassword && (
            <span
              onClick={this.togglePasswordMask}
              className={passwordIconClasses}
            />
          )}
          {iconAfter && (
            <InputGroupAddon onClick={onClickAfter} addonType="append">
              <span className={iconAfterClasses} />
            </InputGroupAddon>
          )}
        </span>
      </FormGroup>
    );
  }
}

InteractiveInput.propTypes = propTypes;
InteractiveInput.defaultProps = defaultProps;

export default InteractiveInput;
