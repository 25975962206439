import classNames from 'classnames';
import { warnOnce } from '../Utilities/utils';

const useInput = (props) => {
  const {
    state, // deprecated
    static: staticInput, // deprecated
    addon,
    bsSize,
    className,
    innerRef,
    invalid,
    plaintext,
    readonly,
    size,
    tag,
    valid,
    ...attributes
  } = props;

  const { children, type } = attributes;

  let computedTag;
  if (type === 'select' || type === 'textarea') {
    computedTag = type;
    delete attributes.type;
  } else if (plaintext) {
    computedTag = 'p';
    delete attributes.type;
  } else {
    computedTag = tag || 'input';
    if (computedTag === 'input' && children) {
      warnOnce(
        'Prism `Input`s that render as html `input`s cannot have children. Please use the `tag` prop to define a different tag, or use the `value` or `defaultValue` attribute to specify the value.'
      );
      delete attributes.children;
    }
  }

  const computedClassName = classNames({
    [className]: className,
    'form-control': true,
    'is-invalid': invalid,
    'is-valid': valid,
    'is-readonly': readonly,
    [`form-control-${size}`]: size,
    [`form-control-${bsSize}`]: bsSize,
    'form-check-input': type === 'radio' || type === 'checkbox',
    'form-control-plaintext': plaintext,
    'form-control-file': type === 'file',
  });

  return {
    tag: computedTag,
    className: computedClassName,
    readOnly: readonly,
    ref: innerRef,
    ...attributes,
  };
};

export default useInput;
