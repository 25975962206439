import Checkbox from './Checkbox';
import ControlledCheckbox from './ControlledCheckbox';
import CheckboxList from './CheckboxList';

Checkbox.List = CheckboxList;
Checkbox.Controlled = ControlledCheckbox;

export { CheckboxList, ControlledCheckbox };

export default Checkbox;
