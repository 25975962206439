import Form from './Form';
import FormFeedback from './FormFeedback';
import FormGroup from './FormGroup';
import FormText from './FormText';

Form.FormFeedback = FormFeedback;
Form.FormGroup = FormGroup;
Form.FormText = FormText;

export { FormFeedback, FormGroup, FormText };

export default Form;
