import React from 'react';
import PropTypes from 'prop-types';
import { deprecated } from '../Utilities/utils';
import useInput from './useInput';

const Input = (props) => {
  const { tag: Tag, ...attributes } = useInput(props);
  return <Tag {...attributes} />;
};

Input.propTypes = {
  state: deprecated(
    PropTypes.string,
    'Please use the props "valid" and "invalid" to indicate the state.'
  ),
  static: deprecated(PropTypes.bool, 'Please use the prop "plaintext"'),
  addon: PropTypes.bool,
  bsSize: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  invalid: PropTypes.bool,
  plaintext: PropTypes.bool,
  size: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  type: PropTypes.string,
  valid: PropTypes.bool,
  readonly: PropTypes.bool,
};

Input.defaultProps = {
  state: undefined, // deprecated
  static: undefined, // deprecated
  addon: false,
  bsSize: '',
  children: null,
  className: '',
  innerRef: null,
  invalid: undefined,
  plaintext: false,
  size: '',
  tag: 'input',
  type: 'text',
  valid: undefined,
  readonly: false,
};

export default Input;
