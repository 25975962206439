import React from 'react';
import classNames from 'classnames';

function DropToggle(props) {
  const {
    children,
    heading,
    toggleMenu,
    isFocused,
    disabled,
    invalid,
    valid,
    ...attributes
  } = props;

  const isValid = valid || valid == 'true';
  const isInvalid = invalid || invalid == 'true';
  const isDisabled = disabled || disabled == 'true';

  const buttonClass = classNames(
    `prism-select__toggle`,
    'form-control',
    isFocused && 'focus',
    isDisabled && 'is-disabled',
    isInvalid && 'is-invalid',
    isValid && 'is-valid'
  );
  return (
    <button
      disabled={isDisabled}
      className={buttonClass}
      onClick={toggleMenu.bind(this)}
      {...attributes}
    >
      {children || heading}
    </button>
  );
}
export default DropToggle;
