import React from 'react';
import PropTypes from 'prop-types';

import Button from '@prism/button';

const ModalCancel = ({ text = 'CANCEL', size = null, ...props }) => (
  <Button textonly size={size} {...props}>
    {text}
  </Button>
);

ModalCancel.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
};

export default ModalCancel;
