import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

const propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  required: PropTypes.bool,
  counts: PropTypes.string,
  className: PropTypes.string,
};
const defaultProps = {
  type: 'radio',
  onChange: () => {},
};

function RadioButton(props) {
  const {
    name,
    required,
    id,
    counts,
    className,
    disabled,
    label,
    checked,
    onClick,
    onChange,
    value,
  } = props;

  let checkId = id || uuid();

  const classes = classNames(
    className,
    'prism-radio ',
    disabled && 'prism-radio--disabled'
  );

  return (
    <div className={classes}>
      <label onChange={onChange} className=" align-items-center d-flex">
        <input
          id={checkId}
          name={name || 'radio'}
          // required={required || null}
          type="radio"
          onClick={onClick}
          value={value}
          {...props}
        />
        <label htmlFor={checkId} className=" itemCol">
          {label}
        </label>
        {counts && (
          <div className="d-flex ml-auto">
            <span className="prism-radio__counts">{counts}</span>
          </div>
        )}
      </label>
    </div>
  );
}

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;
export default RadioButton;
