import Dropdown from './Dropdown';
import DropdownMenu from './DropdownMenu';
import DrOption from './DrOption';
import DrOptionGroup from './DrOptionGroup';
import DropToggle from './DropToggle';

if (Dropdown) {
  Dropdown.Menu = DropdownMenu;
  Dropdown.Option = DrOption;
  Dropdown.OptionGroup = DrOptionGroup;
  Dropdown.Toggle = DropToggle;
}

export { DropdownMenu, DrOption, DrOptionGroup, DropToggle };

export default Dropdown;
