import {
  useEffect, useRef, useState,
} from 'react';

import {
  nextFrame,
  sanitize,
  bindOutsideClick,
  unbindOutsideClick,
} from './utils';

import { MORE_LABEL, SHOW_ALL } from './CONSTANTS';

export const initialState = {
  action: null,
  actions: [],
  iconOnly: false,
  menuLabel: MORE_LABEL,
  remainingActions: [],
  show: 2,
  showPanel: false,
  textOnly: false,
  visibleActions: [],
};

const toActionOrSpacer = (action) => {
  const { spacer, type, label, props } = action;
  const isSpacer = (
    spacer
    || (type
      && type.name
      && type.name.toString() === 'Spacer'));
  if (isSpacer) {
    return { spacer: true };
  }
  if (type) {
    return { ...(props || {}) };
  }
  if (label) {
    return { ...action };
  }
  return null;
};

const useActions = (defaultState = {}) => {
  const panelRef = useRef(null);
  const [state, setState] = useState(sanitize(defaultState, initialState));
  const [allActions, setAllActions] = useState([]);
  const [showPanel, setShowPanel] = useState(state.showPanel || false);

  const hidePanel = () => {
    setShowPanel(false);
  };

  useEffect(() => {
    if (showPanel) {
      nextFrame(() => {
        if (panelRef.current) {
          panelRef.current.focus();
        }
        bindOutsideClick(hidePanel);
      });
    } else {
      unbindOutsideClick(hidePanel);
    }

    return () => {
      unbindOutsideClick(hidePanel);
    };
  }, [showPanel]);

  /**
   * Return the number of visible items that will be shown
   * @param {number} actionSize total number of actions to be counted
   * @returns the number of items to be shown
   */
  const getShowCount = (actionSize) => {
    const { show } = state;
    const maxShowCount = typeof actionSize !== 'undefined'
      ? actionSize
      : (allActions || []).length;

    if (show === SHOW_ALL) {
      return maxShowCount;
    }
    if (typeof show === 'number') {
      return Math.min(Math.max(0, show), maxShowCount);
    }

    return 0;
  };

  /**
   * Splits the provided actions array into two arrays based on the size
   * provided ignoring Action spacers in the process.
   * @param {array} actions array of actions to split
   * @param {number} size max size of the visible array
   * @returns array of visible and hidden actions
   */
  const splitActions = (actions = [], size = 0) => {
    const nonSpacerActions = actions.filter((i) => !i.spacer);
    return [
      nonSpacerActions.slice(0, size),
      nonSpacerActions.slice(size, -1),
    ];
  };

  const setActions = (actions = []) => {
    const newActions = actions.map(toActionOrSpacer).filter((a) => !!a);
    const show = getShowCount(newActions.length);

    const [visibleActions, remainingActions] = splitActions(newActions, show);

    setState({
      ...state,
      visibleActions,
      remainingActions,
    });

    setAllActions(newActions);
  };

  const getActionState = (actionIndex) => {
    const show = getShowCount(allActions.length);

    if (actionIndex < 0) {
      return {};
    }

    const props = allActions[actionIndex] || {};
    const blendedState = { ...state, ...props };

    const actionState = sanitize(
      blendedState,
      {
        addOnAppend: 0,
        addOnPrepend: 0,
        className: 0,
        iconOnly: 0,
        inline: 0,
        label: 0,
        onClick: 0,
        textOnly: 0,
      },
      {}
    );

    return {
      ...actionState,
      ...(actionIndex < show ? {} : { iconOnly: false, textOnly: false }),
    };
  };

  return [
    {
      ...state,
      show: getShowCount(),
      panelRef,
      showPanel,
    },
    {
      getActionState,
      setActions,
      setShowPanel,
    },
  ];
};

export default useActions;
