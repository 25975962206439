import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fade from '@prism/fade';
import { mapToCssModules } from '../Utilities/utils';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  closeAriaLabel: PropTypes.string,
  cssModule: PropTypes.object,
  color: PropTypes.string,
  fade: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  transition: PropTypes.shape(Fade.propTypes),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};

const defaultProps = {
  isOpen: true,
  tag: 'div',
  closeAriaLabel: 'Close',
  fade: true,
  color: 'success',
  transition: {
    ...Fade.defaultProps,
    unmountOnExit: true,
  },
};

function Message(props) {
  const {
    className,
    closeClassName,
    closeAriaLabel,
    cssModule,
    tag: Tag,
    color,
    isOpen,
    toggle,
    children,
    transition,
    fade,
    title,
    innerRef,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(className, 'alert', 'prism-message', `prism-message--${color}`, {
      'alert-dismissible': toggle,
    }),
    cssModule
  );

  const closeClasses = mapToCssModules(
    classNames('close', closeClassName),
    cssModule
  );

  const textClasses = classNames(
    title ? 'prism-message__text--with-title' : 'prism-message__text'
  );

  const iconClasses = classNames('icon', {
    'prism-icon-info': color === 'info',
  });
  const alertTransition = {
    ...Fade.defaultProps,
    ...transition,
    baseClass: fade ? transition.baseClass : '',
    timeout: fade ? transition.timeout : 0,
  };

  return (
    <Fade
      {...attributes}
      {...alertTransition}
      tag={Tag}
      className={classes}
      in={isOpen}
      role="alert"
      innerRef={innerRef}
    >
      {toggle ? (
        <button
          type="button"
          className={closeClasses}
          aria-label={closeAriaLabel}
          onClick={toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}

      <div className="prism-message__body">
        <i className={iconClasses} />
        {title && <span className="prism-message__title">{title}</span>}
        <span className={textClasses}>{children}</span>
      </div>
    </Fade>
  );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default Message;
