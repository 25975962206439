import React, { createContext, useContext } from 'react';
import useActions, { initialState } from './useActions';
import { sanitize } from './utils';

const Context = createContext([initialState, {}]);

export const Provider = ({ children, ...defaultState }) => {
  const [state, fns] = useActions(defaultState);
  const props = { children };
  return <Context.Provider {...props} value={[state, fns]} />;
};

export const withProvider = (Component) => (props) => {
  const providerProps = sanitize(props, initialState, {});

  return (
    <Provider {...providerProps}>
      <Component {...props} />
    </Provider>
  );
};

export const useActionsContext = () => useContext(Context);

export default Context;
