import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Chip from '@prism/chip';
import Checkbox from './Checkbox';

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  childrenClassName: PropTypes.string,
  disabled: PropTypes.bool,
  chipColor: PropTypes.oneOf([
    Chip.COLOR_LIGHT,
    Chip.COLOR_MEDIUM,
    Chip.COLOR_DARK,
  ]),
};

const defaultProps = {
  onChange: () => {},
  data: [],
  disabled: false,
  chipColor: Chip.COLOR_MEDIUM,
};

function CheckboxList({ disabled, data, chipColor, onChange, ...props }) {
  return (
    <div className="check-list">
      {data &&
        data.map((item, i) => (
          <div key={uuid()} className="check-list__item">
            <Checkbox
              disabled={disabled || item.disabled}
              checked={item.checked || false}
              name={item.name || item.label || ''}
              count={item.count}
              label={item.label || item.name || ''}
              value={item.value || ''}
              id={item.id || uuid()}
              onChange={onChange}
              className="check-list__item"
            />
            {item.count && item.count > 0 && (
              <Chip color={chipColor} className="filter-count">
                {item.count}
              </Chip>
            )}
          </div>
        ))}
    </div>
  );
}

CheckboxList.propTypes = propTypes;
CheckboxList.defaultProps = defaultProps;

export default CheckboxList;
