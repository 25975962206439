import Input from './Input';
// // import InputGroupButton from './InputGroupButton';
import InteractiveInput from './InteractiveInput';
import Label from './Label';
import NumberInput from './NumberInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import TextCounter from './TextCounter';

// Input.GroupButton = InputGroupButton;
Input.Interactive = InteractiveInput;
Input.Label = Label;
Input.NumberInput = NumberInput;
Input.Password = PasswordInput;
Input.Phone = PhoneInput;
Input.TextCounter = TextCounter;

export {
  // InputGroupButton,
  InteractiveInput,
  Label,
  NumberInput,
  PasswordInput,
  PhoneInput,
  TextCounter,
};

export default Input;
