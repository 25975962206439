import classNames from 'classnames';

export default function useButton(props) {
  const {
    actionLink, // deprecated
    aux, // deprecated
    floatingAction, // deprecated
    outline, // deprecated
    textonly, // deprecated
    active,
    block,
    children,
    className,
    close,
    color,
    disabled,
    icononly,
    type,
    size,
    tag,
    innerRef,
    variant: variantFromProps,
    onClick,
    ...attributes
  } = props;

  // Returns the name (string) of the first deprecated prop that is truthy
  const getVariantFromProps = (deprecatedVariantProps) => Object.keys(deprecatedVariantProps).find(
    (key) => !!deprecatedVariantProps[key]
  );

  // Map deprecated props to `variant` constant
  const variant = variantFromProps
    || getVariantFromProps({
      floatingAction,
      outline,
      aux,
      textonly,
      actionLink,
    });

  const Tag = tag || (attributes.href ? 'a' : 'button');

  // Type depends on the Tag - default is <button type="button" />
  const computedType = type || (Tag === 'button' ? 'button' : null);

  const computedSize = size || (icononly ? 'sm' : '');

  const classes = classNames({
    [className]: className,
    close,
    btn: !close && variant !== 'actionLink',
    [`btn-${computedSize}`]: !close && computedSize,
    [`btn-${color}`]: !close && color,
    disabled,
    active,
    'btn-icon-only': icononly && variant !== 'floatingAction',
    'btn-block': block && variant !== 'floatingAction',
    'btn-action-link': variant === 'actionLink',
    link: variant === 'actionLink',
    'btn-floating-action': variant === 'floatingAction',
    'btn-auxiliary': variant === 'aux',
    'btn-outline': variant === 'outline',
    'btn-textonly': variant === 'textonly' || variant === 'aux',
  });

  const handleOnClick = (e) => {
    if (!onClick || !e) return;
    if (!props.href && e) {
      e.preventDefault();
    }
    onClick(e);
  };

  const tagProps = {
    ...attributes,
    type: computedType,
    disabled,
    className: classes,
    ref: innerRef,
    onClick: handleOnClick,
  };

  if (!attributes['aria-label'] && close) {
    tagProps['aria-label'] = 'Close';
  }

  // Flag indicating the button should render as a standard 'close' button
  const isClose = close && !children;

  return {
    Tag,
    tagProps,
    isClose,
    children,
  };
}
