import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules } from '../Utilities/utils';

const propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  indeterminate: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};
const defaultProps = {
  type: 'checkbox',
  onChange: null,
  innerRef: null,
  idx: null,
};

class ControlledCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uncontrolledChecked: props.checked,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { onChange } = this.props;
    this.setState({ checked: !this.state.checked });

    onChange && onChange(e);
  }

  render() {
    const {
      required,
      id,
      checked,
      disabled,
      label,
      name,
      type,
      value,
      className,
      cssModule,
      indeterminate,
      innerRef,
      idx,
      ...attributes
    } = this.props;

    const classes = mapToCssModules(
      classNames(className, indeterminate && `${type}-indeterminate`),
      cssModule
    );

    return (
      <div className="checkbox--custom">
        <label htmlFor={id} className="itemCol itemContent checkbox-label">
          <input
            id={id}
            name={name || null}
            required={required || null}
            type={type}
            className={classes}
            checked={checked}
            disabled={disabled}
            value={value}
            indeterminate={indeterminate}
            onChange={this.handleChange}
            ref={innerRef || null}
            idx={idx}
            {...attributes}
          />
          <span className="checkbox-label">{label}</span>
        </label>
      </div>
    );
  }
}

ControlledCheckbox.propTypes = propTypes;
ControlledCheckbox.defaultProps = defaultProps;

export default ControlledCheckbox;
