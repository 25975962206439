import { IconSvg } from './IconSvg';

export { useIcon, IconNames } from './useIcon';

const Icon = IconSvg;
Icon.SVG = IconSvg;

export { IconSvg, Icon };

export default Icon;
