import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@prism/button';
import Icon from '@prism/icon';

import { useActionsContext } from '../Context';
import {
  CNAME_ACTION,
  CNAME_BUTTON,
  CNAME_ICON,
  CNAME_ICON_ONLY,
  CNAME_LABEL,
  CNAME_INLINE,
  CNAME_TEXT_ONLY,
} from '../CONSTANTS';

const propTypes = { actionIndex: PropTypes.number };

const defaultProps = { actionIndex: 0 };

const Action = ({ actionIndex, ...props }) => {
  const [, { getActionState = () => ({}) }] = useActionsContext();

  const {
    addOnAppend,
    addOnPrepend,
    className: propClassName,
    iconOnly,
    inline,
    label,
    textOnly,
    ...attrs
  } = { ...getActionState(actionIndex), ...props };

  const iconNode = (glyph, labelText, className = '') => (
    <Icon
      className={classnames(CNAME_ICON, className)}
      glyph={glyph}
      title={labelText}
    />
  );

  const className = classnames(
    CNAME_ACTION,
    CNAME_BUTTON,
    {
      [CNAME_ICON_ONLY]: iconOnly,
      [CNAME_TEXT_ONLY]: textOnly,
      [CNAME_INLINE]: inline,
    },
    propClassName
  );

  return (
    <Button
      variant="actionLink"
      aria-label={label || null}
      className={className}
      {...attrs}
    >
      {!textOnly && addOnPrepend ? iconNode(addOnPrepend, label) : null}
      {!iconOnly && label ? <span className={CNAME_LABEL}>{label}</span> : null}
      {!textOnly && addOnAppend ? iconNode(addOnAppend, label) : null}
    </Button>
  );
};

Action.propTypes = propTypes;
Action.defaultProps = defaultProps;
Action.displayName = 'Action';

export default Action;
